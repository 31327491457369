import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Kiosk, KioskLocation } from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { setErrorInsteadOfThrowing } from "../../common/component-helpers";
import { CoreService } from "../../common/core.service";

@Component({
  selector: "app-kiosks-detail",
  template: require('./kiosks-detail.component.html')
})
export class KiosksDetailComponent implements OnChanges {
  @Input() kiosk: Kiosk;
  @Input() open = false;
  @Output() update = new EventEmitter();

  locations: Array<KioskLocation>;
  isLoading = false;
  error: boolean | string = false;
  isSuccess = false;

  constructor(private core: CoreService, public analytics: AnalyticsService) {}

  @setErrorInsteadOfThrowing
  async ngOnChanges(changes): Promise<void> {
    for (const propName in changes) if (changes[propName].currentValue) this.reset();

    if (this.kiosk && !this.locations) {
      this.locations = await this.core.api.getKioskLocations();
      this.analytics.trackEvent("Kiosk locations fetched", "Kiosks", { isInteractive: false });
    }
  }

  async updateKiosk(): Promise<void> {
    this.isLoading = true;
    this.error = false;
    this.isSuccess = false;
    try {
      this.analytics.trackEvent("Kiosk update submitted", "Kiosks");
      await this.core.api.updateKiosk(this.kiosk.id, {
        description: this.kiosk.description,
        locationId: this.kiosk.location.id
      });
      this.analytics.trackEvent("Kiosk updated", "Kiosks", { isInteractive: false });
      this.update.emit();
      this.isLoading = false;
      this.isSuccess = true;
    } catch (e) {
      this.analytics.trackEvent("Kiosk update failed", "Kiosks", { isInteractive: false });
      this.error = true;
      this.isSuccess = false;
      this.isLoading = false;
    }
  }

  reset(): void {
    this.error = false;
    this.isSuccess = false;
    this.isLoading = false;
  }
}
