import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-permission-required",
  template: require('./permission-required.component.html'),
  styles: [require('./permission-required.component.scss')]
})
export class PermissionRequiredComponent implements OnInit {
  constructor() {} // tslint:disable-line no-empty
  ngOnInit(): void {} // tslint:disable-line no-empty
}
