import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { CreditPlan, getActivePurses, getTotalAvailableFunds, Purse, User, UserLevel, UserType } from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { CoreService } from "../../common/core.service";

@Component({
  selector: "app-student-detail",
  template: require('./detail.component.html')
})
export class StudentDetailComponent implements OnChanges {
  @Input() user: User;
  @Input() open = false;
  @Output() update = new EventEmitter();

  isLoading = false;
  message: string;
  error: string;
  showSuccess = false;
  showFailure = false;
  zeroFailure = false;
  userTypes = [
    { label: "Type 0", value: UserType.Type0 },
    { label: "Type 1", value: UserType.Type1 },
    { label: "Type 2", value: UserType.Type2 },
    { label: "Type 3", value: UserType.Type3 },
    { label: "Type 4", value: UserType.Type4 },
    { label: "Type 5", value: UserType.Type5 },
    { label: "Type 6", value: UserType.Type6 },
    { label: "Type 7", value: UserType.Type7 },
    { label: "Type 8", value: UserType.Type8 },
    { label: "Type 9", value: UserType.Type9 }
  ];
  userLevels = [
    { label: "Level 0", value: UserLevel.Level0 },
    { label: "Level 1", value: UserLevel.Level1 },
    { label: "Level 2", value: UserLevel.Level2 }
  ];
  updatingUserType = false;
  updatingUserLevel = false;
  showTypeSuccess = false;
  showLevelSuccess = false;
  showTypeError = false;
  showLevelError = false;

  collapsedUnloadedPlans: { [key: string]: boolean } = {};

  constructor(private core: CoreService, public analytics: AnalyticsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) if (changes[propName].currentValue) this.reset();
  }

  reset(): void {
    this.isLoading = false;
    this.message = undefined;
    this.error = undefined;
    this.showSuccess = false;
    this.showFailure = false;
    this.zeroFailure = false;
    this.updatingUserType = false;
    this.updatingUserLevel = false;
    this.showTypeError = false;
    this.showLevelError = false;
    this.showTypeSuccess = false;
    this.showLevelSuccess = false;

    if (this.user)
      this.collapsedUnloadedPlans = this.user.unloadedCreditPlans
        .map(x => x.id)
        .reduce((obj, x) => ({ ...obj, [x]: true }), {});
  }

  getPurses(): Array<Purse> {
    return getActivePurses(this.user.purses);
  }

  getTotalFunds(): number {
    return getTotalAvailableFunds(this.user);
  }

  async loadFunds(purseId: number, planId: number, amount: number): Promise<void> {
    this.message = undefined;
    this.error = undefined;
    this.showSuccess = false;
    this.showFailure = false;
    this.isLoading = true;
    this.analytics.trackEvent("Submitted virtual funds load", "Students");
    try {
      await this.core.api.increasePurseBalance(this.user.username, purseId, planId, amount);
      this.update.emit();
      this.showSuccess = true;
      this.analytics.trackEvent("Virtual funds loaded", "Students", { isInteractive: false });
    } catch (e) {
      this.error = e.message;
      this.showFailure = true;
      this.analytics.trackEvent("Virtual funds load failed", "Students", { isInteractive: false });
    }

    this.isLoading = false;
  }

  async loadFundsToPurse(purse: Purse, amount: number): Promise<void> {
    return this.loadFunds(purse.id, purse.planId, amount);
  }

  async loadFundsForUnloadedPlan(plan: CreditPlan, amount: number): Promise<void> {
    return this.loadFunds(0, plan.id, amount);
  }

  async updateUserType(): Promise<void> {
    this.reset();
    this.updatingUserType = true;
    this.analytics.trackEvent("Submitted user type", "Students");
    try {
      await this.core.api.setUserType(this.user.username, this.user.type);
      this.showTypeSuccess = true;
      this.updatingUserType = false;
      this.analytics.trackEvent("User type loaded", "Students", { isInteractive: false });
    } catch (e) {
      this.error = e.message;
      this.showTypeError = true;
      this.updatingUserType = false;
      this.analytics.trackEvent("User type update failed", "Students", { isInteractive: false });
    }
  }

  async updateUserLevel(): Promise<void> {
    this.reset();
    this.updatingUserLevel = true;
    this.analytics.trackEvent("Submitted user level", "Students");
    try {
      await this.core.api.setUserLevel(this.user.username, this.user.level);
      this.showLevelSuccess = true;
      this.updatingUserLevel = false;
      this.analytics.trackEvent("User level updated", "Students", { isInteractive: false });
    } catch (e) {
      this.error = e.message;
      this.showLevelError = true;
      this.updatingUserLevel = false;
      this.analytics.trackEvent("User level update failed", "Students", { isInteractive: false });
    }
  }

  now(): Date {
    return new Date();
  }
}
