import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgSelectModule } from "@ng-select/ng-select";
import { AccessDeniedComponent, NoContentComponent } from "../errors";
import { AuthComponent } from "./auth.component";
import { AuthGuard } from "./auth.guard";
import { UnauthGuard } from "./unauth.guard";

@NgModule({
  imports: [CommonModule, BrowserModule, NgSelectModule, FormsModule],
  declarations: [AuthComponent, NoContentComponent, AccessDeniedComponent],
  providers: [AuthGuard, UnauthGuard]
})
export class AuthModule {}
