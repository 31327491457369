import { Injectable } from "@angular/core";

@Injectable()
export class FileService {
  saveText(data: string, filename: string): void {
    const blob = new Blob([data], { type: "text/plain" });

    // for IE/Edge: use `msSaveOrOpenBlob`
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);

      return;
    }

    // for everyone else: create <a href="blob" /> and click on it
    const linkElement = document.createElement("a");
    const objectUrl = URL.createObjectURL(blob);
    linkElement.href = objectUrl;
    linkElement.download = filename;
    document.body.appendChild(linkElement);
    linkElement.click();
    setTimeout(() => {
      linkElement.remove();
      window.URL.revokeObjectURL(objectUrl);
    });
  }

  saveUrl(url: string): void {
    window.location.replace(url);
  }
}
