import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-purse",
  template: `
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <span>{{ purseName }}</span>
        <span>Valid
          <span title="{{ startDate | date:'yyyy-MM-dd HH:mm:ss' }}">
            {{ startDate | date:"yyyy-MM-dd" }}
          </span>
           —
          <span title="{{ endDate | date:'yyyy-MM-dd HH:mm:ss' }}">
            {{ endDate | date:"yyyy-MM-dd" }}
          </span>
        </span>
      </div>
      <div class="card-body">
        <h5 class="card-text mb-2">
          {{ startBalance | currency:'USD':'symbol' }}
          <small class="text-muted">loaded, </small>
          {{ currentBalance | currency:'USD':'symbol' }}
          <small class="text-muted">available</small>
        </h5>
        <form #balanceForm="ngForm" class="form-inline">
          <div class="input-group mb-0">
            <input type="number" class="form-control" placeholder="Amount to add" name="amount" [(ngModel)]="amount" required />
            <div class="input-group-append">
              <button class="btn btn-secondary" type="button" [disabled]="isLoading || !balanceForm.valid" (click)="handleSubmit()">Load</button>
            </div>
          </div>
          <div *ngIf="invalidAmount" class="alert alert-danger">Load amount should be greater than $0 and not greater than \${{ MAX_AMOUNT }}.</div>
        </form>
      </div>
    </div>
  `,
  // TODO: normalizing back to Bootstrap 4.1 styles. Remove after refactoring admin theme to be compatible with it.
  styles: [
    `
      .card {
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
      }

      .card-header {
        padding: 0.75rem 1.25rem;
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      }

      .card-header::after {
        content: none;
      }
    `
  ]
})
export class PurseComponent {
  @Input() purseName: string;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() startBalance: number;
  @Input() currentBalance: number;
  @Input() amount: string;

  @Input() isLoading: boolean;
  @Output() loadFunds = new EventEmitter<number>();

  MAX_AMOUNT = 200;

  invalidAmount = false;

  handleSubmit(): void {
    const enteredAmount = parseFloat(this.amount);
    if (enteredAmount > 0 && enteredAmount <= this.MAX_AMOUNT) {
      this.invalidAmount = false;
      this.loadFunds.emit(enteredAmount);
      this.amount = undefined;
    } else this.invalidAmount = true;
  }
}
