export function setErrorInsteadOfThrowing(target: any, propertyKey: string, descriptor: PropertyDescriptor): void {
  const originalMethod = descriptor.value;

  descriptor.value = async function(...args): Promise<any> {
    // tslint:disable no-invalid-this
    let result;
    try {
      result = await originalMethod.call(this, ...args);
    } catch (err) {
      this.error = err.message;
      result = undefined;
    }

    if (this.cdRef) this.cdRef.detectChanges();

    return result;
    // tslint:enable no-invalid-this
  };
}
