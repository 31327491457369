import { Component, Input, OnInit } from "@angular/core";
import { TabComponent } from "./tab.component";

@Component({
  selector: "app-tabs",
  template: `
    <ul class="nav nav-tabs">
      <li *ngFor="let tab of tabs" (click)="selectTab(tab)" class="nav-item">
        <a [ngClass]="{active: tab.isActive(), 'nav-link': true}" href="javascript:void(0);">{{ tab.tabTitle }}</a>
      </li>
    </ul>
    <ng-content></ng-content>
  `
})
export class TabsComponent implements OnInit {
  @Input() disabled: boolean;

  tabs: Array<TabComponent> = [];

  addTab(tab: TabComponent): void {
    this.tabs.push(tab);
  }

  selectTab(tab: TabComponent): void {
    this.tabs.forEach(t => {
      t.setActive(t === tab);
    });
  }

  ngOnInit(): void {
    const initialTab = this.tabs.find(tab => tab.isActive());
    this.selectTab(initialTab || this.tabs[0]);
  }
}
