import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { PortalPermissions } from "wepa-api";
import { CoreService } from "../common/core.service";
import { SessionService } from "../common/session.service";

@Injectable()
export class AuthGuard implements CanActivate {
  private userPermissions: PortalPermissions;

  constructor(private router: Router, private core: CoreService, private session: SessionService) {}

  async canActivate(route, state): Promise<boolean> {
    if (!this.core.isUserAuthenticated) {
      this.router.navigate(["/login"]);

      return false;
    }

    if (state.url.split("/").length < 3) return false;
    const module = state.url.split("/")[1];
    const action = state.url.split("/")[2];

    try {
      this.userPermissions = await this.session.getPermissions();
      if (this.hasPermission(module, action) || module === "permission_required") return true;
    } catch (e) {} // tslint:disable-line:no-empty

    this.core.unauthUser();
    this.router.navigate(["/login"]);

    return false;
  }

  canView(module): boolean {
    return this.hasPermission(module, "view") || this.hasPermission(module, "manage");
  }

  hasPermission(module, action): boolean {
    if (module === "supply_management")
      return (
        this.userPermissions.includes("MANAGE_SUPPLY_INVENTORY") ||
        this.userPermissions.includes(`${action.toUpperCase()}_${module.toUpperCase()}`)
      );

    return this.userPermissions.includes(`${action.toUpperCase()}_${module.toUpperCase()}`);
  }
}
