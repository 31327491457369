import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Sidebar } from "ng-sidebar";
import { Kiosk, KioskLocation } from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { CoreService } from "../../common/core.service";
import { SessionService } from "../../common/session.service";

@Component({
  selector: "app-locations",
  template: require('./locations.component.html'),
  styles: [require('./locations.component.scss')],
  encapsulation: ViewEncapsulation.None // tslint:disable-line use-view-encapsulation
})
export class LocationsComponent implements OnInit {
  locations: Array<KioskLocation>;
  kiosksByLocation: Record<string, Array<Kiosk>> = {};
  isSidebarOpen = false;
  selectedLocation?: KioskLocation;
  error: string;
  requiredPermission = false;

  @ViewChild("sidebar") private sidebar: Sidebar;

  constructor(private core: CoreService, private session: SessionService, public analytics: AnalyticsService) {}

  ngOnInit(): void {
    this.refreshLocations();
    this.fetchKiosks();
    this.sidebar.onClosed.subscribe(() => {
      this.analytics.trackEvent("Closed location details", "Kiosk Locations");
    });
  }

  async refreshLocations(): Promise<void> {
    try {
      const userPermissions = await this.session.getPermissions();
      if (userPermissions.includes("VIEW_KIOSKS")) {
        this.requiredPermission = false;
        this.locations = await this.core.api.getKioskLocations();
        this.analytics.trackEvent("Locations refreshed", "Kiosk Locations", { isInteractive: false });
      } else {
        this.locations = [];
        this.requiredPermission = true;
      }
    } catch (e) {
      this.error = e.message;
      this.analytics.trackEvent("Locations refresh failed", "Kiosk Locations", { isInteractive: false });
    }
  }

  async fetchKiosks(): Promise<void> {
    const kiosks = await this.core.api.getKiosks();
    this.kiosksByLocation = {};
    kiosks.forEach(x => {
      this.kiosksByLocation[x.location.id] = this.kiosksByLocation[x.location.id] || [];
      this.kiosksByLocation[x.location.id].push(x);
    });
  }

  addLocation(): void {
    this.selectedLocation = undefined;
    this.sidebar.open();
    this.analytics.trackEvent("Started creation of a new location", "Kiosk Locations");
  }

  selectLocation(location: KioskLocation): void {
    this.selectedLocation = location;
    this.sidebar.open();
    this.analytics.trackEvent("Opened location details", "Kiosk Locations");
  }

  kiosksOfLocation(location: KioskLocation): Array<Kiosk> {
    return this.kiosksByLocation[location.id] || [];
  }
}
