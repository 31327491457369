const STUFF_REPLACED_IN_PROD_USING_SED = {
  API_BASE_URL: "<% API_BASE_URL %>",
  CLIENT_ID: "<% CLIENT_ID %>",
  CLIENT_SECRET: "<% CLIENT_SECRET %>",
  GA_UA: "<% GA_UA %>"
};

const DEFAULT_ENV = {
  API_BASE_URL: "https://development-api.wepanow.com",
  CLIENT_ID: "wepa-dashboard",
  CLIENT_SECRET: "dash%T^Y",
  GA_UA: "UA-123774164-1"
};

// `process.env` is whatever it is during compilation, not in runtime. There is no runtime, we're in a browser.
export const isProduction = process.env.ENV === "production";

export const API_BASE_URL = STUFF_REPLACED_IN_PROD_USING_SED.API_BASE_URL.startsWith("<%")
  ? DEFAULT_ENV.API_BASE_URL
  : STUFF_REPLACED_IN_PROD_USING_SED.API_BASE_URL;
export const CLIENT_ID = STUFF_REPLACED_IN_PROD_USING_SED.CLIENT_ID.startsWith("<%")
  ? DEFAULT_ENV.CLIENT_ID
  : STUFF_REPLACED_IN_PROD_USING_SED.CLIENT_ID;
export const CLIENT_SECRET = STUFF_REPLACED_IN_PROD_USING_SED.CLIENT_SECRET.startsWith("<%")
  ? DEFAULT_ENV.CLIENT_SECRET
  : STUFF_REPLACED_IN_PROD_USING_SED.CLIENT_SECRET;
export const IS_GA_ENABLED = document.location.hostname === "portal.wepanow.com";
export const GA_UA = STUFF_REPLACED_IN_PROD_USING_SED.GA_UA.startsWith("<%")
  ? DEFAULT_ENV.GA_UA
  : STUFF_REPLACED_IN_PROD_USING_SED.GA_UA;
