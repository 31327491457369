import { Component } from "@angular/core";

@Component({
  selector: "app-access-denied",
  template: `
    <div>
      <h1>403: Access denied</h1>
    </div>
  `
})
export class AccessDeniedComponent {}
