import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SidebarModule } from "ng-sidebar";
import { NgxPaginationModule } from "ngx-pagination";
import { NgSelectModule } from "@ng-select/ng-select";
import { ChartModule } from "angular2-highcharts";

import { DashboardComponent } from "./dashboard.component";
import { StudentsComponent } from "./students/students.component";
import { StudentDetailComponent } from "./students/detail.component";
import { PurseComponent } from "./students/purse.component";
import { KiosksComponent } from "./kiosks/kiosks.component";
import { LocationsComponent } from "./locations/locations.component";
import { KiosksDetailComponent } from "./kiosks/kiosks-detail.component";
import { LocationsDetailComponent } from "./locations/locations-detail.component";
import { ReportsComponent } from "./reports/reports.component";
import { StatsComponent } from "./stats/stats.component";
import { ScreensaversComponent } from "./screensavers/screensavers.component";
import { ScreensaversDetailComponent } from "./screensavers/screensavers-detail.component";
import { SupplyComponent } from "./supply/supply.component";
import { SupplyDetailComponent } from "./supply/supply-detail.component";
import { UsersComponent } from "./users/users.component";
import { UsersDetailComponent } from "./users/users-detail.component";
import { PermissionRequiredComponent } from "./permission-required/permission-required.component";
import { TabsComponent } from "../common/tabs/tabs.component";
import { TabComponent } from "../common/tabs/tab.component";
import { ClosePopoverOnClickOutsideDirective } from "../common/closePopoverOnClickOutside.directive";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgxPaginationModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    SidebarModule.forRoot(),
    ChartModule.forRoot(
      // tslint:disable no-require-imports
      require("highcharts/highstock"),
      require("highcharts/modules/exporting"),
      require("highcharts/modules/heatmap"),
      require("highcharts/modules/drilldown"),
      require("highcharts/highcharts-3d")
      // tslint:enable no-require-imports
    )
  ],
  declarations: [
    DashboardComponent,
    StudentsComponent,
    PurseComponent,
    StudentDetailComponent,
    KiosksDetailComponent,
    KiosksComponent,
    LocationsComponent,
    LocationsDetailComponent,
    ReportsComponent,
    StatsComponent,
    ScreensaversComponent,
    ScreensaversDetailComponent,
    SupplyComponent,
    SupplyDetailComponent,
    UsersComponent,
    UsersDetailComponent,
    PermissionRequiredComponent,
    TabsComponent,
    TabComponent,
    ClosePopoverOnClickOutsideDirective
  ]
})
export class DashboardModule {}
