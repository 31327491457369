import { Component, OnInit, ViewChild } from "@angular/core";
import debounce from "debounce";
import { Sidebar } from "ng-sidebar";
import { getTotalAvailableFunds, User } from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { setErrorInsteadOfThrowing } from "../../common/component-helpers";
import { CoreService } from "../../common/core.service";

@Component({
  selector: "app-students",
  template: require('./students.component.html')
})
export class StudentsComponent implements OnInit {
  searchQuery: string;
  searchResults: Array<User>;

  selectedUser: User;
  error: string;
  status = "Perform search to view users";

  @ViewChild("sidebar") private sidebar: Sidebar;

  constructor(private core: CoreService, public analytics: AnalyticsService) {
    this.searchStudents = debounce(this.searchStudents, 300);
  }

  ngOnInit(): void {
    this.sidebar.openedChange.subscribe(isOpened => {
      this.analytics.trackEvent(`${isOpened ? "Opened" : "Closed"} student details`, "Students");
    });
  }

  @setErrorInsteadOfThrowing
  async searchStudents(): Promise<void> {
    // TODO: This isn't type-safe and will break if `debounce` changes their interface.
    (this.searchStudents as any).clear();
    this.error = undefined;

    if (this.searchQuery.length < 3) {
      this.status = "Enter at least 3 characters in search";

      return;
    }

    this.searchResults = undefined;
    this.status = "Searching...";
    this.analytics.trackEvent("Typed in a query, searching for students", "Students");
    const list = await this.core.api.searchUsers(this.searchQuery);

    if (list.length > 0) {
      this.searchResults = list;
      if (this.selectedUser) this.selectedUser = this.searchResults.find(x => x.id === this.selectedUser.id);
      this.analytics.trackEvent("Fetched student search results", "Students", { isInteractive: false });
    } else {
      this.searchResults = undefined;
      this.status = "No results found";
      this.analytics.trackEvent("No students found after search", "Students", { isInteractive: false });
    }
  }

  getTotalFunds(user: User): number {
    return getTotalAvailableFunds(user);
  }

  selectUser(user: User): void {
    this.selectedUser = user;
    this.sidebar.open();
  }
}
