import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { CoreService } from "../common/core.service";

@Injectable()
export class UnauthGuard implements CanActivate {
  constructor(private core: CoreService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    if (this.core.isUserAuthenticated) {
      this.router.navigate(["/students/view"]);

      return false;
    }

    return true;
  }
}
