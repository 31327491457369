import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NgbDateAdapter, NgbDateNativeAdapter } from "@ng-bootstrap/ng-bootstrap";
import { Screensaver, SubGroup } from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { CoreService } from "../../common/core.service";

@Component({
  selector: "app-screensavers-detail",
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  template: require('./screensavers-detail.component.html'),
  styles: [require('./screensavers.component.scss')]
})
export class ScreensaversDetailComponent implements OnChanges {
  @Input() screensaver: Screensaver;
  @Input() subGroups: Array<SubGroup>;
  @Output() update = new EventEmitter();

  @ViewChild("image") inputEl: ElementRef;

  description: string;
  isLoading = false;
  showFailure = false;
  showSuccess = false;
  uploadSuccess = false;
  uploadError = false;
  error: string;
  editingScreensaver: Screensaver = {} as any;

  constructor(private core: CoreService, public analytics: AnalyticsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ("screensaver" in changes && changes.screensaver.currentValue) {
      this.reset();
      this.editingScreensaver = { ...this.screensaver };
    }
  }

  save(): void {
    this.isLoading = true;
    this.showFailure = false;
    this.showSuccess = false;

    if (!this.editingScreensaver.id) this.uploadScreensaver();
    else this.updateScreensaver();
  }

  reset(): void {
    this.showFailure = false;
    this.showSuccess = false;
    this.isLoading = false;
  }

  getSubGroups(): Array<SubGroup> {
    return [{ id: undefined, name: "All" }, ...this.subGroups];
  }

  isSupportedFileSelected(): boolean {
    return !!(
      this.inputEl &&
      this.inputEl.nativeElement.files.length &&
      /\.(png|jpg|jpeg)$/i.test(this.inputEl.nativeElement.files[0].name)
    );
  }

  isUnsupportedFileSelected(): boolean {
    return !!(this.inputEl && this.inputEl.nativeElement.files.length && !this.isSupportedFileSelected());
  }

  async updateScreensaver(): Promise<void> {
    this.analytics.trackEvent("Screensaver update submitted", "Marketing");
    try {
      await this.core.api.updateScreensaver(this.editingScreensaver);
      this.isLoading = false;
      this.showSuccess = true;
      this.update.emit();
      this.analytics.trackEvent("Screensaver updated", "Marketing", { isInteractive: false });
    } catch (e) {
      this.showFailure = true;
      this.showSuccess = false;
      this.isLoading = false;
      this.analytics.trackEvent("Screensaver update failed", "Marketing", { isInteractive: false });
    }
  }

  async uploadScreensaver(): Promise<void> {
    const inputEl: HTMLInputElement = this.inputEl.nativeElement;

    this.analytics.trackEvent("Screensaver creation submitted", "Marketing");
    try {
      await this.core.api.uploadScreensaver(inputEl.files[0], inputEl.files[0].name, {
        isActive: this.editingScreensaver.isActive,
        activeStartDate: this.editingScreensaver.activeStartDate,
        activeEndDate: this.editingScreensaver.activeEndDate,
        subGroupId: this.editingScreensaver.subGroupId
      });
      this.update.emit();
      this.uploadSuccess = true;
      this.showFailure = false;
      this.showSuccess = true;
      this.isLoading = false;
      this.analytics.trackEvent("Screensaver created", "Marketing", { isInteractive: false });
    } catch (e) {
      this.uploadError = true;
      this.showFailure = true;
      this.showSuccess = false;
      this.isLoading = false;
      this.analytics.trackEvent("Screensaver creation failed", "Marketing", { isInteractive: false });
    }
  }
}
