import { DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "./app.component";
import { ROUTES } from "./app.routes";
import { AuthModule } from "./auth/auth.module";
import { AnalyticsService } from "./common/analytics.service";
import { CoreService } from "./common/core.service";
import { HeaderComponent } from "./common/header/header.component";
import { SessionService } from "./common/session.service";
import { SidebarComponent } from "./common/sidebar/sidebar.component";
import { DashboardModule } from "./dashboard/dashboard.module";
import { MenuService } from "./common/menu.service";

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, SidebarComponent, HeaderComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(ROUTES, { useHash: true, preloadingStrategy: PreloadAllModules }),
    AuthModule,
    DashboardModule
  ],
  exports: [RouterModule],
  providers: [DatePipe, CoreService, SessionService, AnalyticsService, MenuService]
})
export class AppModule {
  ngDoBootstrap(): void {
    return undefined;
  }
}
