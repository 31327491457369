import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from "@angular/core";
import { SupplyBinInventory } from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { setErrorInsteadOfThrowing } from "../../common/component-helpers";
import { CoreService } from "../../common/core.service";

@Component({
  selector: "app-supply-detail",
  encapsulation: ViewEncapsulation.None, // tslint:disable-line use-view-encapsulation
  template: require('./supply-detail.component.html'),
  styles: [require('./supply-detail.component.scss')]
})
export class SupplyDetailComponent implements OnChanges {
  @Input() inventory: SupplyBinInventory;
  @Input() groupName: string;
  @Input() userId: string;
  @Output() update = new EventEmitter();

  isLoading = false;
  error: boolean | string = false;
  isSuccess = false;

  constructor(private core: CoreService, public analytics: AnalyticsService) {}

  @setErrorInsteadOfThrowing
  async ngOnChanges(changes): Promise<void> {
    for (const propName in changes) if (changes[propName].currentValue) this.reset();
  }

  async updateSupplyBinInventory(): Promise<void> {
    this.error = false;
    this.isLoading = true;
    this.isSuccess = false;

    try {
      await this.core.api.updateSupplyBinInventory(this.groupName, this.userId, this.inventory);
      this.isLoading = false;
      this.isSuccess = true;
      this.update.emit();
    } catch (e) {
      this.error = true;
      this.isLoading = false;
      this.isSuccess = false;
    }
  }

  reset(): void {
    this.error = false;
    this.isSuccess = false;
    this.isLoading = false;
  }
}
