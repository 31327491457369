import { Injectable } from "@angular/core";

@Injectable()
export class MenuService {
  private _isMenuOpen = false;

  get isMenuOpen(): boolean {
    return this._isMenuOpen;
  }

  toggleMenu(): void {
    this._isMenuOpen = !this._isMenuOpen;
  }
}
