import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { PrinterStatus } from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { setErrorInsteadOfThrowing } from "../../common/component-helpers";
import { CoreService } from "../../common/core.service";
import { FileService } from "../../common/file.service";
import { SessionService } from "../../common/session.service";

import chartJobsTemplate from "./chart-jobs.json";
import chartKiosksHeatmapTemplate from "./chart-kiosks-heatmap.json";
import chartYearPrintsTemplate from "./chart-year-prints.json";

const months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const fullMonths = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const datePipe = new DatePipe("en-US");
const toIsoDate = (date: any): string => datePipe.transform(date, "yyyy-MM-dd");
const deepClone = obj => JSON.parse(JSON.stringify(obj));

@Component({
  selector: "app-stats",
  template: require('./stats.component.html'),
  styles: [require('./stats.component.scss')],
  providers: [FileService]
})
export class StatsComponent implements OnInit {
  recentJobsChartSettings: Object;
  heatmapChartSettings: Object;
  yearPrintStatsChartSettings: Object;
  recentJobsDataForExport: any;

  today = new Date();
  showTodayPanel = false;
  todayJobs = 0;
  todayPrints = 0;
  redKiosks = 0;
  yellowKiosks = 0;
  greenKiosks = 0;

  error: string;

  private groupName: string;

  constructor(
    private core: CoreService,
    private session: SessionService,
    private fileService: FileService,
    private analytics: AnalyticsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.groupName = (await this.session.getUser()).groupName;
    this.fetchRecentJobs();
    this.fetchYearPrintStats();
    this.fetchHeatmapData();
    this.fetchKioskStatusStats();
    this.fetchTodayStats();
  }

  @setErrorInsteadOfThrowing
  async fetchRecentJobs(): Promise<void> {
    // TODO: Use CoreService once this method is implemented in `wepa-api`.
    const res = await this.core.api.getPrintSourceStatsByMonth(
      this.groupName,
      this.today.getFullYear(),
      this.today.getMonth() + 1
    );
    this.recentJobsDataForExport = res;
    this.recentJobsChartSettings = this.composeRecentJobsChartSettings(res);
  }

  @setErrorInsteadOfThrowing
  async fetchYearPrintStats(): Promise<void> {
    // TODO: Use CoreService once this method is implemented in `wepa-api`.
    const res = await this.core.api.getMonthlyPrintStats(this.groupName, 12);
    this.yearPrintStatsChartSettings = this.composeYearPrintStatsChartSettings(res);
  }

  @setErrorInsteadOfThrowing
  async fetchHeatmapData(): Promise<void> {
    const fromDate = new Date();
    const toDate = new Date();
    fromDate.setDate(toDate.getDate() - 8);
    toDate.setDate(toDate.getDate() - 1);

    const res = await this.core.api.getPrintHeatmapDataByDateRange(this.groupName, fromDate, toDate);
    this.heatmapChartSettings = this.composeHeatmapChartSettings(res, fromDate, toDate);
  }

  @setErrorInsteadOfThrowing
  async fetchKioskStatusStats(): Promise<void> {
    const kiosks = await this.core.api.getKiosks();
    const withStatus = status => kiosks.filter(k => k.status.printerStatus === status).length;
    this.greenKiosks = withStatus(PrinterStatus.Green);
    this.yellowKiosks = withStatus(PrinterStatus.Yellow);
    this.redKiosks = withStatus(PrinterStatus.Red);
  }

  @setErrorInsteadOfThrowing
  async fetchTodayStats(): Promise<void> {
    const res = await this.core.api.getPrintStatsByDate(this.groupName, this.today);
    if (res) {
      this.todayJobs = res.colorJobs + res.monoJobs;
      this.todayPrints = res.pagesPrinted;
    } else {
      this.todayJobs = 0;
      this.todayPrints = 0;
    }
    this.showTodayPanel = true;
  }

  // TODO: Refactor this method.
  composeRecentJobsChartSettings(jobsData: Array<any>): Object {
    const jobsDataArray = [];
    const jobsSecondarySourcesDonutArray = [];
    let totalJobs = 0;
    jobsData.forEach(job => {
      {
        const existingItem = jobsDataArray.find(x => x.name === job.source);
        if (existingItem) existingItem.y += job.pagesPrinted;
        else jobsDataArray.push({ name: job.source, y: job.pagesPrinted, drilldown: job.source });
      }

      {
        const existingItem = jobsSecondarySourcesDonutArray.find(x => x.name === name);
        if (existingItem) existingItem.y += job.pagesPrinted;
        else
          jobsSecondarySourcesDonutArray.push({
            name: job.secondarySource === "NONE" ? job.source : job.secondarySource,
            y: job.pagesPrinted
          });
      }

      totalJobs += job.pagesPrinted;
    });

    const chart = deepClone(chartJobsTemplate);

    chart.title.text =
      `Pages printed in <b>${fullMonths[this.today.getMonth() + 1]} ` +
      `${this.today.getFullYear()}</b> — ${totalJobs}`;
    chart.series[0].data = jobsDataArray;
    chart.series[0].dataLabels.formatter = function(): string | null {
      // TODO: Review whether this has to be `null` and not `undefined`.
      return this.percentage > 5 ? this.point.name : null; // tslint:disable-line no-null-keyword no-invalid-this
    };
    chart.series[1].data = jobsSecondarySourcesDonutArray;

    return chart;
  }

  composeYearPrintStatsChartSettings({ aggregated_prints: datapoints }: { aggregated_prints: Array<any> }): Object {
    const totalPrints = datapoints.reduce((sum, x) => sum + x.pages_printed, 0);
    const yearPrintsDataArray = datapoints
      .map(x => [`${months[x.month]} '${x.year.toString().slice(2)}`, x.pages_printed])
      .reverse();

    const chart = deepClone(chartYearPrintsTemplate);

    chart.title.text = `Total pages printed in the last 12 months — ${totalPrints}`;
    chart.series[0].data = yearPrintsDataArray;

    return chart;
  }

  // TODO: Refactor this method.
  composeHeatmapChartSettings(res: Array<any>, fromDate: Date, toDate: Date): Object {
    const heatmapDataArray: Array<[number, number, number]> = [];
    const kioskNames: Array<string> = [];
    const dateNames: Array<string> = [];

    res.forEach(x => {
      const kioskName = x.kioskDescription ? `${x.kioskDescription}: ${x.kioskName}` : x.kioskName;
      let kioskIndex = kioskNames.indexOf(kioskName);
      if (kioskIndex === -1) {
        kioskNames.push(kioskName);
        kioskIndex = kioskNames.length - 1;
      }

      const dateName = toIsoDate(x.printDate);
      let dateIndex = dateNames.indexOf(dateName);
      if (dateIndex === -1) {
        dateNames.push(dateName);
        dateIndex = dateNames.length - 1;
      }

      let pagesPrinted = 0;
      for (let hour = 0; hour <= 23; hour += 1) pagesPrinted += x[`hour${hour}Pages`];

      heatmapDataArray.push([kioskIndex, dateIndex, pagesPrinted]);
    });

    for (let kioskIndex = 0; kioskIndex < kioskNames.length; kioskIndex++)
      for (let dateIndex = 0; dateIndex < dateNames.length; dateIndex++)
        if (!heatmapDataArray.find(x => x[0] === kioskIndex && x[1] === dateIndex))
          heatmapDataArray.push([kioskIndex, dateIndex, 0]);

    const chart = deepClone(chartKiosksHeatmapTemplate);

    chart.title.text = `Kiosk usage from <b>${toIsoDate(fromDate)}</b> to <b>${toIsoDate(toDate)}</b>`;
    chart.xAxis.categories = kioskNames;
    chart.yAxis.categories = dateNames;
    chart.tooltip.formatter = function(): string {
      // tslint:disable no-invalid-this
      const date = this.series.yAxis.categories[this.point.y];
      const pages = this.point.value;
      const printer = this.series.xAxis.categories[this.point.x];
      // tslint:enable no-invalid-this

      return `<b>${printer}</b> printed <br /><b>${pages}</b> pages on <br /><b>${date}</b>`;
    };
    chart.series[0].data = heatmapDataArray;

    return chart;
  }

  downloadRecentJobsAsCsv(): void {
    // TODO: Review whether `undefined` should be handled like `null` here.
    const serializeValue = value => JSON.stringify(value, (key, val) => (val === null ? "" : val));
    const serializeDateValue = value => JSON.stringify(toIsoDate(new Date(value)));
    const serializeField = (field, value) => (field === "printDate" ? serializeDateValue : serializeValue)(value);
    const serializeRow = row => fields.map(field => serializeField(field, row[field])).join(",");

    const fields = Object.keys(this.recentJobsDataForExport[0]);
    const headerRow = fields.join(",");
    const csv = [headerRow, ...this.recentJobsDataForExport.map(serializeRow)].join("\r\n");

    this.fileService.saveText(csv, "data.csv");

    this.analytics.trackEvent("Recent print sources data downloaded as CSV", "Stats");
  }
}
