import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Group, GroupAuthType } from "wepa-api";
import { AnalyticsService } from "../common/analytics.service";
import { CoreService } from "../common/core.service";
import { SessionService } from "../common/session.service";

const initialRouters = [
  "students",
  "dataapi",
  "pentaho_reports",
  "screensavers",
  "locations",
  "kiosks",
  "supply_management"
];

@Component({
  selector: "app-auth",
  // ng-select styles break if view encapsulation is enabled.
  encapsulation: ViewEncapsulation.None, // tslint:disable-line use-view-encapsulation
  template: require('./auth.component.html'),
  styles: [require('./auth.component.scss')]
})
export class AuthComponent implements OnInit {
  @ViewChild("usernameInput") usernameInput: ElementRef<HTMLInputElement>;

  groupList: Array<Group> = [];
  isLoading = true;
  authError: boolean;
  shibAuthUrl: string;

  username: string;
  usernameSuffix: string;
  password: string;

  childAuthWindow: Window | null;

  allowedOrigins = ['https://sp2.wepanow.com', 'https://googleagent.wepanow.com', 'cas'];


  constructor(
    private router: Router,
    private core: CoreService,
    private session: SessionService,
    public analytics: AnalyticsService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const groups = await this.core.api.getGroups("portal");
      this.groupList = groups.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1));
      this.analytics.trackEvent("Group list fetched", "Auth", { isInteractive: false });

      window.addEventListener('message', (event) => {
        // Abort if request doesn't come from a valid origin
        if (!this.isWepanowURL(event.origin)) return;

        const eventData = JSON.parse(event.data);
        const credentials = {
          'email': eventData.email,
          'cookie': eventData.cookie
        };
        
        if (credentials) {
          this.childAuthWindow.close()
          this.childAuthWindow = null;
          this.onShibResult(credentials);
        }
      })
    } catch (e) {
      this.analytics.trackEvent("Group list fetch failed", "Auth", { isInteractive: false });
    }
    this.isLoading = false;
  }

  isWepanowURL(url: string): boolean {
    try {
      const parsedUrl = new URL(url);
      const domain = parsedUrl.hostname;

      return domain === "wepanow.com" || domain.endsWith('.wepanow.com');
    } catch (error) {
      // The URL is not valid or cannot be parsed
      return false;
    }
  }

  async handleAuthPromise(authPromise): Promise<void> {
    try {
      await authPromise;
      this.analytics.trackEvent("Successfully authenticated", "Auth", { isInteractive: false });
      const userPermissions = await this.session.getPermissions();
      for (const initialRouter of initialRouters)
        if (userPermissions.includes(`VIEW_${initialRouter.toUpperCase()}`)) {
          this.router.navigate([`${initialRouter}/view`]);

          return;
        }
      this.router.navigate(["permission_required/view"]);
    } catch (e) {
      this.analytics.trackEvent("Authentication failed", "Auth", { isInteractive: false });
      this.shibAuthUrl = undefined;
      this.authError = true;
    }
    this.isLoading = false;
  }

  onSubmit(): void {
    this.isLoading = true;
    this.authError = false;
    const fullUsername =
      this.username + (this.usernameSuffix && !this.username.includes("@") ? this.usernameSuffix : "");
    this.handleAuthPromise(this.core.authUser(fullUsername, this.password));
    this.analytics.trackEvent("Username and password submitted", "Auth");
  }

  onGroupSelected(group: Group): void {
    if (!group) {
      this.usernameSuffix = undefined;
      this.shibAuthUrl = undefined;
      this.analytics.trackEvent("Group deselected", "Auth");

      return;
    }

    if (group.auth.type === GroupAuthType.Session) {
      this.shibAuthUrl = group.auth.shibUrl;
      this.childAuthWindow = window.open(this.shibAuthUrl, "_blank");
      this.analytics.trackEvent("Group selected (shib)", "Auth", { label: group.name });
    } else {
      this.childAuthWindow = null;
      this.shibAuthUrl = undefined;
      this.usernameSuffix = group.content.emailSuffix;
      this.usernameInput.nativeElement.focus();
      this.analytics.trackEvent("Group selected (federated)", "Auth", { label: group.name });
    }
  }

  extractCredentials(win): { email: string; cookie: string } | undefined {
    try {
      // this will only work on `*.wepanow.com`, not cross-origin pages.
      const emailElement = win.document.querySelector("#u-email");
      const cookieElement = win.document.querySelector("#u-cookie");
      if (!emailElement || !cookieElement) return undefined;

      return {
        email: emailElement.textContent,
        cookie: cookieElement.textContent
      };
    } catch (e) {
      console.log("Error while fetching credentials: ", e);
      return undefined;
    }
  }

  onShibResult(credentials): void {
    this.isLoading = true;
    this.handleAuthPromise(this.core.authUser(credentials.email, credentials.cookie));
  }
}
