import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Group, Supply, SupplyBin, SupplyBinInventory, SupplyGroup } from "wepa-api";
import { Sidebar } from "ng-sidebar";
import { CoreService } from "../../common/core.service";
import { SessionService } from "../../common/session.service";
import { setErrorInsteadOfThrowing } from "../../common/component-helpers";
import { AnalyticsService } from "../../common/analytics.service";

@Component({
  selector: "app-supply",
  encapsulation: ViewEncapsulation.None, // tslint:disable-line use-view-encapsulation
  template: require('./supply.component.html'),
  styles: [require('./supply.component.scss')]
})
export class SupplyComponent implements OnInit {
  groupList: Array<Group> = [];
  fetchStatus: string;
  supplyBins: Array<SupplyBin>;
  inventories: Array<SupplyBinInventory>;
  inventory: SupplyBinInventory;
  inventoryForDetail: SupplyBinInventory;
  groupName: string;
  userId: string;
  userPermissions: Array<string>;
  isPowerUser = false;
  loading = true;
  error = false;
  success = false;

  @ViewChild("sidebar") private sidebar: Sidebar;

  constructor(private core: CoreService, private session: SessionService, public analytics: AnalyticsService) {}

  async ngOnInit(): Promise<void> {
    this._setEmptyState();

    try {
      const user = await this.core.api.getUser();
      this.groupName = user.groupName;
      this.userId = user.id;

      this.userPermissions = await this.session.getPermissions();
      this.isPowerUser = this.userPermissions.includes("MANAGE_SUPPLY_INVENTORY");

      if (this.isPowerUser) {
        const groups = await this.core.unAuthApi.getGroups("portal");
        this.groupList = groups.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1));
      } else if (this.groupName) await this.getSupplyBinInventories();
    } catch (e) {} // tslint:disable-line no-empty

    this.loading = false;
    this.sidebar.openedChange.subscribe(isOpened => {
      this.analytics.trackEvent(`${isOpened ? "Opened" : "Closed"} User details`, "Users");
      if (isOpened) this.inventoryForDetail = JSON.parse(JSON.stringify(this.inventory));
    });
  }

  async onGroupSelected(group: Group): Promise<void> {
    this.error = false;
    this.inventories = undefined;
    this.inventory = undefined;
    if (group) {
      this.groupName = group.name;
      await this.getSupplyBinInventories();
    } else this._setEmptyState();
  }

  @setErrorInsteadOfThrowing
  async getSupplyBinInventories(): Promise<void> {
    this.fetchStatus = "Fetching...";
    const list = (await this.core.api.getSupplyBinInventories(this.groupName, true)).map(_formatInventory);
    if (list.length > 0) {
      this.inventories = list;
      this.inventory = list[0];
      this.analytics.trackEvent("Fetched Supply Bins by group name", "SupplyManagement", { isInteractive: false });
    } else {
      this.inventories = undefined;
      this.inventory = undefined;
      this.fetchStatus = "No results found";
      this.analytics.trackEvent("No Supply Bin found after fetch by group name", "SupplyManagement", {
        isInteractive: false
      });
    }
  }

  viewDetails(inventory: SupplyBinInventory): void {
    this.inventory = inventory;
    this.sidebar.open();
  }

  private _setEmptyState(): void {
    this.fetchStatus = "Supply Bins for group";
  }
}

function _formatInventory(inventory: SupplyBinInventory): SupplyBinInventory {
  return {
    ...inventory,
    supplies: inventory.supplies.map(_formatSupplyGroup)
  };
}

function _formatSupplyGroup(group: SupplyGroup): SupplyGroup {
  return {
    ...group,
    items: unique([
      group.items.find((x: SupplyGroup) => x.name === "Toner"),
      group.items.find((x: SupplyGroup) => x.name === "Drum"),
      group.items.find((x: SupplyGroup) => x.name === "Maintenance"),
      ...group.items
    ]).map(_formatSupplyGroupPart)
  };
}

function _formatSupplyGroupPart(group: SupplyGroup): SupplyGroup {
  return {
    ...group,
    items: unique([
      group.items.find((x: Supply) => x.description === "Black"),
      group.items.find((x: Supply) => x.description === "Cyan"),
      group.items.find((x: Supply) => x.description === "Magenta"),
      group.items.find((x: Supply) => x.description === "Yellow"),
      ...group.items
    ])
  };
}

function unique<T>(list: Array<T>): Array<T> {
  return Array.from(new Set(list)).filter(x => x !== undefined);
}
