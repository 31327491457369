import { Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { StudentsComponent } from "./dashboard/students/students.component";
import { StudentDetailComponent } from "./dashboard/students/detail.component";
import { KiosksComponent } from "./dashboard/kiosks/kiosks.component";
import { LocationsComponent } from "./dashboard/locations/locations.component";
import { SidebarComponent } from "./common/sidebar/sidebar.component";
import { HeaderComponent } from "./common/header/header.component";
import { AuthComponent } from "./auth/auth.component";
import { ReportsComponent } from "./dashboard/reports/reports.component";
import { StatsComponent } from "./dashboard/stats/stats.component";
import { ScreensaversComponent } from "./dashboard/screensavers/screensavers.component";
import { SupplyComponent } from "./dashboard/supply/supply.component";
import { UsersComponent } from "./dashboard/users/users.component";
import { PermissionRequiredComponent } from "./dashboard/permission-required/permission-required.component";

import { AccessDeniedComponent, NoContentComponent } from "./errors";

import { AuthGuard } from "./auth/auth.guard";
import { UnauthGuard } from "./auth/unauth.guard";

export const ROUTES: Routes = [
  { path: "", component: AuthComponent, canActivate: [UnauthGuard] },
  { path: "login", component: AuthComponent, canActivate: [UnauthGuard] },
  {
    path: "students",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", outlet: "sidebar", component: SidebarComponent },
      { path: "", outlet: "header", component: HeaderComponent },
      { path: "view", component: StudentsComponent },
      { path: "view/:id", component: StudentDetailComponent },
      { path: "edit/:id", component: StudentsComponent }
    ]
  },
  {
    path: "kiosks",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", outlet: "sidebar", component: SidebarComponent },
      { path: "", outlet: "header", component: HeaderComponent },
      { path: "view", component: KiosksComponent },
      { path: "edit/:id", component: KiosksComponent }
    ]
  },
  {
    path: "locations",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", outlet: "sidebar", component: SidebarComponent },
      { path: "", outlet: "header", component: HeaderComponent },
      { path: "view", component: LocationsComponent },
      { path: "edit/:id", component: LocationsComponent }
    ]
  },
  {
    path: "pentaho_reports",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", outlet: "sidebar", component: SidebarComponent },
      { path: "", outlet: "header", component: HeaderComponent },
      { path: "view", component: ReportsComponent }
    ]
  },
  {
    path: "dataapi",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", outlet: "sidebar", component: SidebarComponent },
      { path: "", outlet: "header", component: HeaderComponent },
      { path: "view", component: StatsComponent }
    ]
  },
  {
    path: "screensavers",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", outlet: "sidebar", component: SidebarComponent },
      { path: "", outlet: "header", component: HeaderComponent },
      { path: "view", component: ScreensaversComponent }
    ]
  },
  {
    path: "supply_management",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", outlet: "sidebar", component: SidebarComponent },
      { path: "", outlet: "header", component: HeaderComponent },
      { path: "view", component: SupplyComponent }
    ]
  },
  {
    path: "portal_users",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", outlet: "sidebar", component: SidebarComponent },
      { path: "", outlet: "header", component: HeaderComponent },
      { path: "manage", component: UsersComponent }
    ]
  },
  {
    path: "permission_required",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", outlet: "sidebar", component: SidebarComponent },
      { path: "", outlet: "header", component: HeaderComponent },
      { path: "view", component: PermissionRequiredComponent }
    ]
  },
  { path: "access-denied", component: AccessDeniedComponent },
  { path: "**", component: NoContentComponent }
];
