import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { KioskCityCompactInfo, KioskLocation, SupplyBin } from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { setErrorInsteadOfThrowing } from "../../common/component-helpers";
import { CoreService } from "../../common/core.service";

@Component({
  selector: "app-locations-detail",
  template: require('./locations-detail.component.html')
})
export class LocationsDetailComponent implements OnChanges {
  @Input() location?: KioskLocation;
  @Input() open = false;
  @Output() update = new EventEmitter();

  isLoading = false;
  error: boolean | string = false;
  isSuccess = false;
  cities: Array<KioskCityCompactInfo>;
  supplyBins: Array<SupplyBin>;

  constructor(private core: CoreService, public analytics: AnalyticsService) {}

  @setErrorInsteadOfThrowing
  async ngOnChanges(changes): Promise<void> {
    for (const propName in changes) if (changes[propName].currentValue) this.reset();

    if (!this.location)
      this.location = {
        id: undefined,
        city: { id: undefined },
        state: { id: undefined },
        supplyBin: { id: undefined }
      } as any;

    if (!this.supplyBins) this.supplyBins = await this.core.api.getSupplyBins();
    this.analytics.trackEvent("Supply bins fetched", "Kiosk Locations", { isInteractive: false });
    if (!this.cities) this.cities = await this.core.api.getKioskCities();
  }

  async updateLocation(): Promise<void> {
    this.isLoading = true;
    this.error = false;
    this.isSuccess = false;
    this.analytics.trackEvent(`Kiosk ${this.location.id ? "update" : "creation"} submitted`, "Kiosk Locations");
    try {
      await this.core.api.updateKioskLocation(this.location);
      this.isLoading = false;
      this.isSuccess = true;
      this.update.emit();
      this.analytics.trackEvent(`Kiosk ${this.location.id ? "updated" : "created"}`, "Kiosk Locations", {
        isInteractive: false
      });
    } catch (err) {
      this.error = true;
      this.isSuccess = false;
      this.isLoading = false;
      this.analytics.trackEvent(`Kiosk ${this.location.id ? "update" : "creation"} failed`, "Kiosk Locations", {
        isInteractive: false
      });
    }
  }

  reset(): void {
    this.error = false;
    this.isSuccess = false;
    this.isLoading = false;
  }
}
