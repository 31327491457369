import { Component, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AnalyticsService } from "./common/analytics.service";
import { GA_UA } from "../env";

// TODO: remove `ViewEncapsulation.None`, move styles into the components that use them.
@Component({
  styles: [require('./app.component.scss')],
  encapsulation: ViewEncapsulation.None, // tslint:disable-line use-view-encapsulation
  template: `
    <router-outlet></router-outlet>
    <div class="view-container">
      <div ng-view class="view-frame"></div>
    </div>`
})
export class AppComponent {
  constructor(private router: Router, private analyticsService: AnalyticsService) {
    this.analyticsService.setUa(GA_UA);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) this.analyticsService.trackPageView(event.urlAfterRedirects);
    });
  }
}
