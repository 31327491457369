import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Sidebar } from "ng-sidebar";
import { Kiosk, PrinterStatus } from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { CoreService } from "../../common/core.service";
import { SessionService } from "../../common/session.service";

@Component({
  selector: "app-kiosks",
  template: require('./kiosks.component.html'),
  styles: [require('./kiosks.component.scss')],
  encapsulation: ViewEncapsulation.None // tslint:disable-line use-view-encapsulation
})
export class KiosksComponent implements OnInit {
  kiosks: Array<Kiosk>;
  selectedKiosk: Kiosk;
  issuesByKiosk: Record<string, Array<any>> = {};
  error: string;

  @ViewChild("sidebar") private sidebar: Sidebar;

  constructor(private core: CoreService, private session: SessionService, public analytics: AnalyticsService) {}

  ngOnInit(): void {
    this.refreshKiosks();
    this.sidebar.openedChange.subscribe(isOpened => {
      this.analytics.trackEvent(`${isOpened ? "Opened" : "Closed"} kiosk details`, "Kiosks");
    });
  }

  async refreshKiosks(): Promise<void> {
    try {
      this.kiosks = await this.core.api.getKiosks();
      this.analytics.trackEvent(`Kiosks refreshed`, "Kiosks", { isInteractive: false });
    } catch (e) {
      this.error = e.message;
      this.analytics.trackEvent(`Kiosks refresh failed`, "Kiosks", { isInteractive: false });
    }
  }

  selectKiosk(kiosk: Kiosk): void {
    this.selectedKiosk = kiosk;
    this.sidebar.open();
  }

  renderStatus(status: PrinterStatus): string {
    const statusToString = new Map([
      [PrinterStatus.Green, "Green"],
      [PrinterStatus.Yellow, "Yellow"],
      [PrinterStatus.Red, "Red"]
    ]);

    return statusToString.get(status) || "Unknown";
  }

  async fetchIssues(kiosk: Kiosk): Promise<void> {
    if (this.issuesByKiosk[kiosk.id] !== undefined) return;

    const user = await this.session.getUser();
    const issues = await this.core.api.getKioskIssues(user.groupName, kiosk.id);
    this.issuesByKiosk[kiosk.id] = issues;
  }

  getDifferenceInMinutes(start: string, end: string): string {
    if (!start || !end) return undefined;

    const ms = +new Date(end) - +new Date(start);
    const minutes = ms / (60 * 1000);

    return minutes.toFixed(1);
  }

  isElementInPopover(el: EventTarget): boolean {
    return "closest" in el ? !!(el as HTMLElement).closest("ngb-popover-window") : false;
  }
}
