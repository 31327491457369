import { Injectable } from "@angular/core";
import { CoreService } from "./core.service";
import { PortalPermissions, User } from "wepa-api";

@Injectable()
export class SessionService {
  private userPromise: Promise<User>;
  private permissionsPromise: Promise<PortalPermissions>;

  constructor(private core: CoreService) {
    core.unauthed.forEach(() => {
      this.userPromise = undefined;
      this.permissionsPromise = undefined;
    });
  }

  getUser(): Promise<User> {
    if (!this.userPromise) this.userPromise = this.core.api.getUser();

    return this.userPromise;
  }

  getPermissions(): Promise<PortalPermissions> {
    if (!this.permissionsPromise) this.permissionsPromise = this.core.api.getUserPortalPermissions();

    return this.permissionsPromise;
  }
}
