import { Component, ViewEncapsulation } from "@angular/core";
import { AuthGuard } from "../../auth/auth.guard";
import { MenuService } from "../menu.service";

@Component({
  selector: "app-sidebar",
  encapsulation: ViewEncapsulation.None, // tslint:disable-line use-view-encapsulation
  template: require('./sidebar.component.html'),
  styles: [require('./sidebar.component.scss')]
})
export class SidebarComponent {
  authGuard: AuthGuard;

  constructor(authGuard: AuthGuard, private menuService: MenuService) {
    this.authGuard = authGuard; // used in the template.
  }

  get isMenuOpen(): boolean {
    return this.menuService.isMenuOpen;
  }
}
