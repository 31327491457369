import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SessionService } from "../session.service";
import { CoreService } from "../core.service";
import { MenuService } from "../menu.service";

@Component({
  selector: "app-header",
  template: require('./header.component.html')
})
export class HeaderComponent implements OnInit {
  userName: string;
  groupDescription: string;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private core: CoreService,
    private menuService: MenuService
  ) {}

  async ngOnInit(): Promise<void> {
    const user = await this.sessionService.getUser();
    this.userName = `${user.firstName} ${user.lastName}`;
    this.groupDescription = user.groupDescription;
  }

  logout(): void {
    this.core.unauthUser();
    this.router.navigate(["/login"]);
  }

  toggleMenu(): void {
    this.menuService.toggleMenu();
  }

  get isMenuOpen(): boolean {
    return this.menuService.isMenuOpen;
  }
}
