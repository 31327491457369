import { Directive, HostListener, Input, TemplateRef } from "@angular/core";

@Directive({
  selector: "[appClosePopoverOnClickOutside]"
})
export class ClosePopoverOnClickOutsideDirective {
  active = false;

  // tslint:disable-next-line:no-input-rename
  @Input("appClosePopoverOnClickOutside")
  popover: {
    ngbPopover: TemplateRef<any>;
    close(): void;
    isOpen(): boolean;
    [key: string]: any;
  };

  @HostListener("document:click", ["$event"])
  docClicked(event: MouseEvent): Boolean {
    if (!this.popover.isOpen()) return (this.active = false);

    const cancelClose = "closest" in event.target ? (event.target as HTMLElement).closest("ngb-popover-window") : false;

    if (!cancelClose) this.popover.close();

    // deactivate if something else closed popover
    this.active = this.popover.isOpen();
  }
}
