import { Component, OnInit, ViewChild } from "@angular/core";
import { Sidebar } from "ng-sidebar";
import { AuthorizationType, Screensaver, SubGroup } from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { CoreService } from "../../common/core.service";
import { SessionService } from "../../common/session.service";

@Component({
  selector: "app-screensavers",
  template: require('./screensavers.component.html'),
  styles: [require('./screensavers.component.scss')]
})
export class ScreensaversComponent implements OnInit {
  screensavers: Array<Screensaver>;
  subGroups: Array<SubGroup> = [];
  selectedScreensaver: Screensaver;
  error: string;
  code: string;
  verified = false;
  codeMessage = "Please wait...";

  isLoading = false;
  isFailed = false;

  @ViewChild("sidebar") private sidebar: Sidebar;

  constructor(private core: CoreService, private sessionService: SessionService, public analytics: AnalyticsService) {}

  async ngOnInit(): Promise<void> {
    this.reset();
    this.isLoading = true;
    try {
      await this.core.api.sendValidationCode(AuthorizationType.ScreensaverUpload);
      this.codeMessage = "Security code sent to your email.";
      this.analytics.trackEvent("Security code sent", "Marketing", { isInteractive: false });
    } catch (e) {
      this.error = e.message;
      this.analytics.trackEvent("Failed to send security code", "Marketing", { isInteractive: false });
    }
    this.isLoading = false;
  }

  selectScreensaver(screensaver): void {
    this.selectedScreensaver = screensaver || { isActive: true };
    this.sidebar.open();
    this.analytics.trackEvent(
      screensaver ? "Opened screensaver details" : "Started creation of a new screensaver",
      "Marketing"
    );
  }

  reset(): void {
    this.isFailed = false;
    this.isLoading = false;
  }

  async refreshScreensavers(): Promise<void> {
    this.isLoading = true;
    try {
      const user = await this.sessionService.getUser();
      this.subGroups = await this.core.api.getSubGroups(user.groupId);
      this.screensavers = await this.core.api.getActiveScreensavers();
      this.analytics.trackEvent("Screensavers refreshed", "Marketing", { isInteractive: false });
    } catch (e) {
      this.error = e.message;
      this.isFailed = true;
      this.analytics.trackEvent("Screensavers refresh failed", "Marketing", { isInteractive: false });
    }
    this.isLoading = false;
  }

  async verify(): Promise<void> {
    this.reset();
    this.isLoading = true;
    this.analytics.trackEvent("Security code submitted", "Marketing");
    try {
      await this.core.api.confirmValidationCode(this.code);
      this.verified = true;
      this.refreshScreensavers();
      this.analytics.trackEvent("Security code verified", "Marketing", { isInteractive: false });

      // sidebar is rendered only after the verification.
      setTimeout(() => {
        this.sidebar.onClosed.subscribe(() => {
          this.analytics.trackEvent("Closed screensaver details", "Marketing");
        });
      });
    } catch (e) {
      this.error = e.message;
      this.isFailed = true;
      this.analytics.trackEvent("Security code verification failed", "Marketing", { isInteractive: false });
    }
    this.isLoading = false;
  }

  getSubGroupNameById(id?: string): string {
    return (this.subGroups.find(x => x.id === id) || { name: "" }).name;
  }
}
