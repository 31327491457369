import { Injectable } from "@angular/core";
import { IS_GA_ENABLED } from "../../env";

@Injectable()
export class AnalyticsService {
  setUa(ua: string): void {
    const script = document.createElement("script");
    script.text =
      "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){" +
      "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o)," +
      "m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)" +
      "})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');";
    document.head.appendChild(script);

    safeGa("create", ua, "auto");
  }

  trackPageView(page: string): void {
    safeGa("set", { page, title: document.title });
    safeGa("send", "pageview", page);
  }

  trackEvent(event: string, category: string, options: { label?: string; isInteractive?: boolean } = {}): void {
    safeGa("send", "event", {
      eventAction: event,
      eventCategory: category,
      eventLabel: options.label,
      nonInteraction: options.isInteractive === undefined ? true : options.isInteractive
    });
  }
}

const safeGa: UniversalAnalytics.ga = ((command, ...args) => {
  if ("ga" in window && IS_GA_ENABLED) ga(command, ...args);
}) as any;
