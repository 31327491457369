import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbDateAdapter, NgbDateNativeAdapter } from "@ng-bootstrap/ng-bootstrap";
import {
  getDefaultBillingName,
  Report,
  ReportBillingName,
  ReportFormInput,
  ReportFormInputType,
  ReportStatus,
  ReportType
} from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { setErrorInsteadOfThrowing } from "../../common/component-helpers";
import { CoreService } from "../../common/core.service";
import { FileService } from "../../common/file.service";
import { SessionService } from "../../common/session.service";
import { TabComponent } from "../../common/tabs/tab.component";
import { TabsComponent } from "../../common/tabs/tabs.component";

@Component({
  selector: "app-reports",
  template: require('./reports.component.html'),
  styles: [require('./reports.component.scss')],
  providers: [FileService, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class ReportsComponent implements OnInit {
  reports: Array<Report>;
  reportTypes: Array<ReportType>;
  billingNames: Array<ReportBillingName>;
  reportInputList: Array<ReportFormInput> = undefined;
  userInputSupplied: any = {};
  generating = false;
  selectedReportTypeId: string;
  savingReportId: string;
  hasReportsInProgress = false;

  errorFlag = false;
  error: string;

  @ViewChild("tabs") private tabs: TabsComponent;
  @ViewChild("tabWithList") private tabWithList: TabComponent;

  private groupName: string;
  private refreshLock = false;
  private pollingHandler: any;
  private POLLING_INTERVAL = 60 * 1000;

  constructor(
    private core: CoreService,
    private session: SessionService,
    private fileService: FileService,
    public analytics: AnalyticsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.groupName = (await this.session.getUser()).groupName;
    this.fetchReports();
    this.fetchReportTypes();
    this.fetchBillingNames();
    this.startStatusPolling();
  }

  async fetchReports(): Promise<void> {
    try {
      this.reports = await this.core.api.getReports(this.groupName);
      this.updateHasReportsInProgress();
      this.analytics.trackEvent("Reports refreshed", "Reports", { isInteractive: false });
    } catch (e) {
      this.error = e.message;
      this.analytics.trackEvent("Reports refresh failed", "Reports", { isInteractive: false });
    }
  }

  async fetchReportTypes(): Promise<void> {
    try {
      this.reportTypes = await this.core.api.getReportTypes(this.groupName);
      this.reportInputList = this.reportTypes[0].formInputs;
      this.selectedReportTypeId = this.reportTypes[0].id;
      this.analytics.trackEvent("Report types fetched", "Reports", { isInteractive: false });
    } catch (e) {
      this.error = e.message;
      this.analytics.trackEvent("Report types fetch failed", "Reports", { isInteractive: false });
    }
  }

  @setErrorInsteadOfThrowing
  async fetchBillingNames(): Promise<void> {
    try {
      this.billingNames = await this.core.api.getReportBillingNames(this.groupName);
      this.userInputSupplied["billingname"] = getDefaultBillingName(this.billingNames).name;
      this.analytics.trackEvent("Report billing names fetched", "Reports", { isInteractive: false });
    } catch (e) {
      this.error = e.message;
      this.analytics.trackEvent("Report billing names fetch failed", "Reports", { isInteractive: false });
    }
  }

  updateHasReportsInProgress(): void {
    this.hasReportsInProgress = (this.reports || []).some(r => r.status === ReportStatus.InProgress);
  }

  showReportInputs(value): void {
    this.selectedReportTypeId = value;
    const report = this.reportTypes.find(r => r.id === value);
    if (report) this.reportInputList = report.formInputs;
  }

  loadInputValue(u, value): void {
    this.userInputSupplied[u.id] = value;
  }

  async generateReport(): Promise<void> {
    this.errorFlag = false;
    this.generating = true;
    this.error = undefined;
    this.analytics.trackEvent("Report form submitted by user", "Reports");
    try {
      const serializedSettings = Object.keys(this.userInputSupplied).reduce(
        (acc, key) => ({
          ...acc,
          [key]:
            this.userInputSupplied[key] instanceof Date
              ? serializeDate(this.userInputSupplied[key])
              : this.userInputSupplied[key]
        }),
        {}
      );
      await this.core.api.generateReport(this.groupName, this.selectedReportTypeId, serializedSettings);
      this.hasReportsInProgress = true;
      this.tabs.selectTab(this.tabWithList);
      this.startStatusPolling();
      this.errorFlag = false;
      this.analytics.trackEvent("Report successfully submitted for generation", "Reports", { isInteractive: false });
    } catch (err) {
      this.error = err.message;
      this.errorFlag = true;
      this.analytics.trackEvent("Report submission failed", "Reports", { isInteractive: false });
    }

    this.generating = false;
  }

  async downloadReport(reportId: string): Promise<void> {
    this.savingReportId = reportId;
    this.analytics.trackEvent("Report download initiated", "Reports");
    try {
      const url = await this.core.api.getReportDownloadUrl(this.groupName, reportId);
      this.fileService.saveUrl(url);
      this.savingReportId = undefined;
      this.analytics.trackEvent("Report download link opened", "Reports", { isInteractive: false });
    } catch (e) {
      this.error = e.message;
      this.errorFlag = true;
      this.savingReportId = undefined;
      this.analytics.trackEvent("Report download failed", "Reports", { isInteractive: false });
    }
  }

  startStatusPolling(): void {
    if (this.pollingHandler) return;

    const refresh = async () => {
      if (this.refreshLock) return;

      this.refreshLock = true;
      await this.fetchReports();

      this.refreshLock = false;
      this.updateHasReportsInProgress();

      if (!this.hasReportsInProgress) this.stopStatusPolling();
    };

    this.pollingHandler = setInterval(refresh, this.POLLING_INTERVAL);
    refresh();
  }

  stopStatusPolling(): void {
    if (this.pollingHandler) {
      clearInterval(this.pollingHandler);
      this.pollingHandler = undefined;
    }
  }

  isStatusAvailable(status: ReportStatus): boolean {
    return status === ReportStatus.Available;
  }

  statusName(status: ReportStatus): string {
    return {
      [ReportStatus.InProgress]: "In progress",
      [ReportStatus.Failed]: "Failed"
    }[status];
  }

  inputTypeName(type: ReportFormInputType): string {
    return {
      [ReportFormInputType.Date]: "DATE",
      [ReportFormInputType.Text]: "TEXT"
    }[type];
  }
}

function serializeDate(date: Date): string {
  return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
}

function padZero(num: number | string): string {
  return num < 10 ? `0${num}` : num.toString();
}
