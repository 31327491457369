import { EventEmitter, Injectable } from "@angular/core";
import { Api, AuthType, createApi, isValidTokenInfo, SerializedTokenInfo } from "wepa-api";
import { API_BASE_URL, CLIENT_ID, CLIENT_SECRET } from "../../env";

const LOCAL_STORAGE_KEY = "wepaCoreAccessToken";

const basicApiConfig = {
  apiBaseUrl: API_BASE_URL,
  client: { id: CLIENT_ID, secret: CLIENT_SECRET }
};

@Injectable()
export class CoreService {
  isUserAuthenticated: boolean;
  api: Api;
  unAuthApi: Api;

  unauthed = new EventEmitter<void>();

  constructor() {
    const savedTokenInfo = getPersistedTokenInfo();
    this.unAuthClient();
    if (savedTokenInfo && isValidTokenInfo(savedTokenInfo)) {
      this.api = createApi({ ...savedTokenInfo, apiBaseUrl: basicApiConfig.apiBaseUrl });
      this.isUserAuthenticated = true;
    } else {
      this.authClient();
      this.isUserAuthenticated = false;
    }
  }

  async authUser(username: string, password: string): Promise<void> {
    const api = createApi({ ...basicApiConfig, grantType: AuthType.PortalAdmin, user: { username, password } });
    await api.authenticate();
    persistTokenInfo(api);
    this.isUserAuthenticated = true;
    this.api = api;
  }

  unauthUser(): void {
    forgetTokenInfo();
    this.isUserAuthenticated = false;
    this.unauthed.emit();
    this.authClient();
  }

  private authClient(): void {
    this.api = createApi({ ...basicApiConfig, grantType: AuthType.Client });
  }

  private unAuthClient(): void {
    this.unAuthApi = createApi({ ...basicApiConfig, grantType: AuthType.Client });
  }
}

function persistTokenInfo(api: Api): void {
  const info = api.serializeTokenInfo();
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(info));
}

function forgetTokenInfo(): void {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
}

function getPersistedTokenInfo(): SerializedTokenInfo | undefined {
  try {
    return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
  } catch (e) {
    return undefined;
  }
}
