import "./polyfills";

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";

try {
  document.domain = "wepanow.com";
} catch (e) {} // tslint:disable-line no-empty

if (process.env.NODE_ENV === "production") enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule);
