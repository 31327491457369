import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Sidebar } from "ng-sidebar";
import { Group, PortalPermissionDtos, User } from "wepa-api";
import { setErrorInsteadOfThrowing } from "../../common/component-helpers";
import { AnalyticsService } from "../../common/analytics.service";
import { CoreService } from "../../common/core.service";
import { Subject } from "rxjs";
import { debounceTime, switchMap, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-users",
  encapsulation: ViewEncapsulation.None, // tslint:disable-line use-view-encapsulation
  template: require('./users.component.html'),
  styles: [require('./users.component.scss')]
})
export class UsersComponent implements OnInit, OnDestroy {
  selectedGroup: Group;
  groupList: Array<Group> = [];
  permissions: PortalPermissionDtos;

  searchQuery = "";
  searchResults: Array<User>;
  portalUsers: Array<User>;
  selectedUser: User;
  searchSubject = new Subject<void>();
  unsubscribe = new Subject<void>();

  error: string;
  searchStatus = "Perform search to view users";
  fetchStatus = "Portal users for group";

  @ViewChild("sidebar") private sidebar: Sidebar;

  constructor(private core: CoreService, public analytics: AnalyticsService) {}

  async ngOnInit(): Promise<void> {
    const groups = await this.core.unAuthApi.getGroups("portal");
    this.groupList = groups.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1));
    this.permissions = await this.core.api.getAllUserPortalPermissions();
    this.sidebar.openedChange.subscribe(isOpened => {
      this.analytics.trackEvent(`${isOpened ? "Opened" : "Closed"} User details`, "Users");
    });

    this.searchSubject
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(300),
        switchMap(query => this.searchUsersByGroupName())
      )
      .subscribe(results => {
        if (results && results.length > 0) {
          this.searchResults = results;
          this.analytics.trackEvent("Fetched user search results", "Users", { isInteractive: false });
        } else {
          this.searchResults = undefined;
          this.searchStatus = "No results found";
          this.analytics.trackEvent("No users found after search", "Users", { isInteractive: false });
        }
      });
  }

  async onGroupSelected(group: Group): Promise<void> {
    this.selectedGroup = group;
    if (this.selectedGroup) {
      await this.getPortalUsers(group.name);

      if (this.searchQuery) this.searchSubject.next();
    }
  }

  @setErrorInsteadOfThrowing
  searchUsersByGroupName(): Promise<Array<User>> {
    this.error = undefined;

    if (this.searchQuery.length < 3) {
      this.searchStatus = "Enter at least 3 characters in search";

      return;
    }

    this.searchResults = undefined;
    this.searchStatus = "Searching...";
    this.analytics.trackEvent("Typed in a query, searching for users", "Users");

    return this.core.api.searchUsersByGroupName(this.searchQuery, this.selectedGroup.name);
  }

  async getPortalUsers(groupName: string): Promise<void> {
    this.fetchStatus = "Fetching...";
    const list = await this.core.api.getPortalUsersByGroupName(groupName);
    if (list.length > 0) {
      this.portalUsers = list;
      this.analytics.trackEvent("Fetched portal users by group name", "UsersForGroup", { isInteractive: false });
    } else {
      this.portalUsers = undefined;
      this.fetchStatus = "No results found";
      this.analytics.trackEvent("No uers found after fetch by group name", "UsersForGroup", { isInteractive: false });
    }
  }

  selectUser(user: User): void {
    this.selectedUser = user;
    this.sidebar.open();
  }

  refreshPermissions(): void {
    setTimeout(() => {
      this.sidebar.close();
      this.selectedUser = undefined;
    }, 1000);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
