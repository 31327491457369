import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from "@angular/core";
import { PortalPermissionDtos, User } from "wepa-api";
import { AnalyticsService } from "../../common/analytics.service";
import { setErrorInsteadOfThrowing } from "../../common/component-helpers";
import { CoreService } from "../../common/core.service";

export interface PermissionCheck {
  permissionName: string;
  description?: string;
  checked: boolean;
  disabled: boolean;
}

@Component({
  selector: "app-users-detail",
  encapsulation: ViewEncapsulation.None, // tslint:disable-line use-view-encapsulation
  template: require('./users-detail.component.html'),
  styles: [require('./users-detail.component.scss')]
})
export class UsersDetailComponent implements OnChanges {
  @Input() user: User;
  @Input() permissions: PortalPermissionDtos;
  @Output() update = new EventEmitter();

  permissionsCheck: Array<PermissionCheck>;
  isLoading = false;
  error: boolean | string = false;
  isSuccess = false;

  constructor(private core: CoreService, public analytics: AnalyticsService) {}

  @setErrorInsteadOfThrowing
  async ngOnChanges(changes): Promise<void> {
    for (const propName in changes) if (changes[propName].currentValue) this.reset();

    if (this.user && this.permissions) {
      this.isLoading = true;
      const userPermissions = await this.core.api.getUserPortalPermissionsById(this.user.id);
      this.isLoading = false;
      this.permissionsCheck = [];
      for (const permission of this.permissions)
        if (!permission.permissionName.includes("MANAGE_")) {
          const disabled = permission.permissionName === "VIEW_PORTAL";
          let checked = disabled;
          for (const userPermission of userPermissions)
            if (permission.permissionName === userPermission) {
              checked = true;
              break;
            }
          this.permissionsCheck.push({
            permissionName: permission.permissionName,
            description: permission.description,
            checked,
            disabled
          });
        }
      this.analytics.trackEvent("User Permissions fetched", "Users", { isInteractive: false });
      this.isLoading = false;
    }
  }

  async updatePermissions(): Promise<void> {
    this.isLoading = true;
    this.error = false;
    this.isSuccess = false;
    const permList = [];
    this.permissionsCheck.forEach(item => {
      if (item.checked) permList.push(item.permissionName);
    });
    try {
      this.analytics.trackEvent("Permissions update submitted", "Users");
      await this.core.api.updateUserPortalPermissionsById(this.user.id, permList);
      this.analytics.trackEvent("Permissions updated", "Users", { isInteractive: false });
      this.isLoading = false;
      this.isSuccess = true;
      this.update.emit();
    } catch (e) {
      this.analytics.trackEvent("Permissions update failed", "Users", { isInteractive: false });
      this.error = true;
      this.isSuccess = false;
      this.isLoading = false;
    }
  }

  reset(): void {
    this.error = false;
    this.isSuccess = false;
    this.isLoading = false;
  }
}
