import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TabsComponent } from "./tabs.component";

@Component({
  selector: "app-tab",
  template: `<div [hidden]="!_isActive"><ng-content></ng-content></div>`
})
export class TabComponent {
  @Input() tabTitle: string;
  @Output() tabOpen = new EventEmitter<boolean>();

  _isActive: boolean;

  constructor(tabs: TabsComponent) {
    tabs.addTab(this);
  }

  setActive(value: boolean): void {
    if (value && this._isActive !== undefined) this.tabOpen.emit(true);
    this._isActive = value;
  }

  isActive(): boolean {
    return this._isActive;
  }
}
